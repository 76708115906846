
import LoginSection from "./loginSection"
import FirstSection from "./firstSection"

function Login() {
    return (
        <>
            <FirstSection/>
            <LoginSection/>
        </>
    );
}

export default Login;