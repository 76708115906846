
import Main from "./Main";

const MediaGallery = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default MediaGallery;
