import React from 'react';

import Main from './Main';

const AboutUs = () => {
  return (
    <>
    <Main/>
    </>
  );
};

export default AboutUs;
