import Section from './Section';

const NewsAndAnnouncements = () => {
    return (
        <>
            <Section/>
        </>
    );
}

export default NewsAndAnnouncements;
