import Main from './Main';

const Donations = () => {

  return (
    <>
        <Main/>
    </>
  );
};

export default Donations;
