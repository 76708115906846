import React from "react";
import { Link } from "react-router-dom";
import { SiMinutemailer } from "react-icons/si";
import {
  FaChevronRight,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

const Footer = () => {
  const usefulLink = [
    { label: "Executives" },
    { label: "Categories" },
    { label: "Committees" },
    { label: "FAQ" },
    { label: "Support" },
    { label: "Schools" },
  ];
  const university = [
    { label: "Categories" },
    { label: "Popular Deal" },
    { label: "FAQ" },
    { label: "Support" },
  ];

  return (
    <>
      <footer className={`bg-blue-950 py-5 text-white`}>
        <div className="mx-8 grid-container">
          <div className="flex flex-col gap-2">
            <article className="flex justify-start">
              <div className="bg-white rounded-full">
                <img
                  className="w-[4rem] h-[4rem] object-cover"
                  src="/Logo.png"
                  alt=""
                />
              </div>
            </article>
            <p className="text-gray-500 font-semibold text-wrap">
              OSAAGOS is a body that wants you to grow and be able to give
              back to the society and community that builds you. Join us in the journey
              to give back to the school that made us!
            </p>
            <div className="flex gap-2 flex-wrap sm:flex-nowrap">
              <p className="font-medium">Phone number : +2348115427845</p>
              <p className="font-medium"> Fax: +2348106606837</p>
            </div>
            <p className="font-medium">Email: info@osaagos.org.ng</p>
          </div>
          <div className="flex flex-col gap-6">
            <h2 className="text-3xl font-semibold">Get In Touch</h2>
            <p>
            Join us in the journey to give back to the school that made us!
            </p>
            <article className="flex items-center bg-[rgba(204,204,204,.3)] overflow-hidden">
              <input
                type="email"
                className="bg-transparent border-0 outline-0 px-4 py-3 inline-flex w-full"
                placeholder="Enter Your Email"
              />
              <span className="sm:px-2 bg-blue-800 px-1 h-full font-bold sm:text-xl text-lg flex items-center cursor-pointer">
                <SiMinutemailer />
              </span>
            </article>
            <div className="flex gap-2">
              <Link
                to={"/"}
                className="bg-blue-800 hover:bg-blue-600 transition-all duration-100 p-2"
              >
                <FaFacebookF />
              </Link>
              <Link
                to={"/"}
                className="bg-blue-800 hover:bg-blue-600 transition-all duration-100 p-2"
              >
                <FaTwitter />
              </Link>
              <Link
                to={"/"}
                className="bg-blue-800 hover:bg-blue-600 transition-all duration-100 p-2"
              >
                <FaLinkedinIn />
              </Link>
              <Link
                to={"/"}
                className="bg-blue-800 hover:bg-blue-600 transition-all duration-100 p-2"
              >
                <FaInstagram />
              </Link>
            </div>
          </div>
          <div className=" flex flex-col gap-6">
            <h2 className="text-3xl font-semibold">Useful Link</h2>

            <ul className="grid sm:grid-cols-2 grid-cols-1 gap-x-8 gap-y-4">
              {usefulLink.map((element, index) => {
                return (
                  <Link
                    to={"/"}
                    key={element.label + index}
                    className="whitespace-nowrap text-white flex items-center font-medium text-sm hover:text-blue-900 hover:translate-x-2 transition-all duration-200"
                  >
                    <FaChevronRight />
                    <span>{element.label}</span>
                  </Link>
                );
              })}
            </ul>
          </div>
          <div className=" flex flex-col gap-6">
            <h2 className="text-3xl font-semibold">Alumni</h2>

            <ul className="flex flex-col gap-4">
              {university.map((element, index) => {
                return (
                  <Link
                    to={"/"}
                    key={element.label + index}
                    className="whitespace-nowrap text-white flex items-center font-medium text-sm hover:text-blue-900 hover:translate-x-2 transition-all duration-200"
                  >
                    <FaChevronRight />
                    <span>{element.label}</span>
                  </Link>
                );
              })}
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
