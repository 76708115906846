import React from 'react';

import Section from './Section';

const ContactUs = () => {
    return (
        <>
            <Section/>
        </>
        
    );
}

export default ContactUs;
