function Responsibilty() {
  return (
    <div className="my-4 py-8 px-8 mb-8">
      <h2 className="text-center sm:text-4xl text-3xl font-bold capitalize md:whitespace-nowrap py-2 mb-12">
        Our Responsibility
      </h2>
      <article className="grid grid-cols-1 lg:grid-cols-4 gap-8 md:grid-cols-3 sm:grid-cols-2">
        <div className="flex flex-col items-center justify-between gap-4 item text-center hover:-translate-y-2 transition-all duration-1000">
          <img src="/01.png" alt="" />
          <h2 className="sm:text-2xl text-xl font-bold text-gray-950 capitalize whitespace-nowrap">Scholarship</h2>
          <p className="font-medium text-gray-800">
            We create capacity building by providing scholarship for deserving 
            students in order to further their academics.
          </p>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 item text-center hover:-translate-y-2 transition-all duration-1000">
          <img src="/02.png" alt="" />
          <h2 className="sm:text-2xl text-xl font-bold text-gray-950 capitalize whitespace-nowrap">
            Help current students
          </h2>
          <p className="font-medium text-gray-800">
            We create capacity building for our current students where they can grow and be successful
            through seasoned discussion and admission advice. 
          </p>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 item text-center hover:-translate-y-2 transition-all duration-1000">
          <img src="/03.png" alt="" />
          <h2 className="sm:text-2xl text-xl font-bold text-gray-950 capitalize whitespace-nowrap">
            Help our school
          </h2>
          <p className="font-medium text-gray-800">
            We contribute meaningfully to the development of our alma mata 
            by sponsoring projects and organising various edutainment programs.
          </p>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 item text-center hover:-translate-y-2 transition-all duration-1000">
          <img src="/04.png" alt="" />
          <h2 className="sm:text-2xl text-xl font-bold text-gray-950 capitalize whitespace-nowrap">
            Build our community
          </h2>
          <p className="font-medium text-gray-800">
            We believe that community is where everyone goes back to, 
            we build our community for our younger generations to grow.
          </p>
        </div>
      </article>
    </div>
  );
}

export default Responsibilty;
