
import Main from "./Main";

const JobBoard = () => {

  return (
    <>
        <Main/>
    </>
);
};

export default JobBoard;
